import React, { useState, useEffect } from "react";
import { BASE_URL } from "../config";
// import jwtAxiosInstance from "../helpers/jwtAxiosInstance.js";
import useAxiosWithInterceptor from "../helpers/jwtinterceptor.js";
import UserTable from "./UserTable.js";
import Navbar from "./Navbar2";

function Users() {

// AXIOS GET
    const [content, setContent] = useState([]);
    // const jwtAxiosGet= jwtAxiosInstance();
    const jwtAxiosGet = useAxiosWithInterceptor();
    
    useEffect(() => {
        jwtAxiosGet
        .get(BASE_URL + "/users/")
        .then((response)=>{
            setContent(response?.data);
            console.log(content);
        })
        .catch(error =>{
            
            console.log(error);
        })
    }, []);

        return (
        <>
            <Navbar/>
            <div className="">
                <h1 className="m-2">Users</h1>
                
                <div className="mx-5">
                <UserTable items={content}/>
                </div>

            </div>
        </>
        );
    

};
export default Users;