import React, { useState, useEffect } from "react";
import { BASE_URL } from "../config";
import useAxiosWithInterceptor from "../helpers/jwtinterceptor.js";
import CollegeTable from "./CollegeTable.js";
import Navbar from "./Navbar2";
function Colleges() {

    const [data, setData] = useState({college:""});
    
    const handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    };
    const clearForm = () => { 
        setData({college:""});
    }; 
// AXIOS GET
    const [content, setContent] = useState([]);
    const jwtAxiosGet= useAxiosWithInterceptor();
    
    useEffect(() => {
        jwtAxiosGet
        .get(BASE_URL + "/colleges/")
        .then((response)=>{
            setContent(response?.data);
        })
        .catch(error =>{
            
            console.log(error);
        })
    }, [data]);

//  AXIOS POST
    const jwtAxiosPost= useAxiosWithInterceptor();
    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = {
            college: data.college,
        };
        jwtAxiosPost
        .post(BASE_URL + "/colleges/", userData)
        .then((response) => {
            console.log(response.status, response.data.token);
            clearForm();
        });
    };
    const getIsFormValid = () => {
        return (data.college);
    };
//   Delays in Rendering to Avoid Flashing Content
    const [isHide, setIsHide] = useState(true);

    setTimeout(() => setIsHide(false), 100);
//  HTML
    
        return (
        <>
            <Navbar/>
            <div className="">
                <h1 className="m-2">Colleges</h1>
                
                {/* {!isHide ?  */}
                <div className="mx-5">
                <form onSubmit={handleSubmit} className="float-start mb-3">
                    <label htmlFor="college">
                    <input className="college-form"
                        type="text"
                        placeholder="Add college"
                        name="college"
                        value={data.college}
                        onChange={handleChange}
                    />
                    </label>
                    <button className="px-md-5 mx-2 align-self-center" type="submit" style={{borderRadius: "3px", color:"#000"}} disabled={!getIsFormValid()}>
                        Add College
                    </button>
                </form>
                <CollegeTable items={content}/>
                </div>
                
                
                {/* : null} */}
            </div>
        </>
        );
    

};
export default Colleges;