import React, { useContext, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import AuthContext from './context/AuthProvider.js';
import "./GoogleLoginButton.css"; 

const GoogleLoginButton = () => {
  const { googleLoginUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccess = async (codeResponse) => {
    setIsLoading(true);
    const authorizationCode = codeResponse.code;

    try {
      const response = await fetch("https://staging.flipbook.dilc.info/api/login-with-google/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: authorizationCode }),
      });
      const data = await response.json();
      googleLoginUser(data.access_token, data.username);
    } catch (error) {
      console.error("Error exchanging authorization code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: "auth-code",
  });

  return (
    <div className="google-login-container">
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <GoogleButton onClick={login} label="Sign in with Google" />
      )}
    </div>
  );
};

export default GoogleLoginButton;
