import React, { useState, useEffect } from "react";
import { BASE_URL } from "../config";
import useAxiosWithInterceptor from "../helpers/jwtinterceptor.js";
import Navbar from "./Navbar2";
import { useParams } from "react-router-dom";
import { dateToString } from "../helpers/helper.js";
import { Link } from "react-router-dom";
function Users() {
    const {id} = useParams();
    const [data, setData] = useState({
        id:id,
        username:"",
        email:"",
        first_name:"",
        last_name:"",
        date_joined:"",
        last_login:"",
        is_active:false,
        is_staff:false,
        is_superuser:false
    });
    
    const handleChange = (e) => {
        const value = e.target.checked;
        setData({
            ...data,
            [e.target.name]: value
        });
    };

    // const jwtAxiosDelete = useAxiosWithInterceptor();
    // const deleteRecord = (e) => {
    //     jwtAxiosDelete.delete(BASE_URL + "/users/" + id)
    //     alert('Deleted user');
    // };

// AXIOS GET
    // const jwtAxiosGet= jwtAxiosInstance();
    const jwtAxiosGet = useAxiosWithInterceptor();
    // window.location.reload()
    useEffect(() => {
        jwtAxiosGet
        .get(BASE_URL + "/users/" + id)
        .then((response)=>{
            setData({...data, username: response.data.username,
                            email: response.data.email,
                            first_name: response.data.first_name, 
                            last_name: response.data.last_name,
                            is_active:response.data.is_active, 
                            is_staff:response.data.is_staff,
                            is_superuser:response.data.is_superuser,
                            date_joined:response.data.date_joined,
                            last_login:response.data.last_login
                    });
        })
        .catch(error =>{
            console.log(error);
        })
    }, []);

//  AXIOS POST
    // const jwtAxiosPost= jwtAxiosInstance();
    const jwtAxiosPost = useAxiosWithInterceptor(); 
    const handleSubmit = (e) => {
        e.preventDefault();
        const userData = {
            is_active: data.is_active,
            is_staff: data.is_staff,
            is_superuser: data.is_superuser
        };
        jwtAxiosPost
        .patch(BASE_URL + "/users/" + id + "/", userData)
        .then((response) => {
            console.log(response.status);
            // clearForm();
            alert("User updated!"); 
        });
    };
    return (
    <>
        <Navbar/>
        <div className="">
            <h1 className="m-2">Change User</h1>
            <div className="mx-5">
                <div className="m-md-4">
                    <p>
                        <span className="fw-semibold">Name: </span> 
                        <span>{data.first_name} {data.last_name}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Username: </span> 
                        <span>{data.username}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Email: </span> 
                        <span>{data.email}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Date joined: </span> 
                        <span>{dateToString(data.date_joined)}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Last login: </span> 
                        <span>{dateToString(data.last_login)}</span>
                    </p>
                </div>
                
                <form onSubmit={handleSubmit} className="mx-4 pb-5">
                    <label htmlFor="user">
                        <input className="user-form"
                        type="checkbox"
                        aria-label="Checkbox to set if user is active"
                        name="is_active"
                        checked = {data.is_active}
                        onChange={handleChange}
                        /> Active status
                    </label>
                    <p><em style={{fontSize:"0.75rem"}}>Designates whether this user should be treated as active. Unselect this instead of deleting accounts.</em></p>

                    <label htmlFor="user">
                        <input className="user-form"
                        type="checkbox"
                        aria-label="Checkbox to set staff privilege"
                        name="is_staff"
                        checked = {data.is_staff}
                        onChange={handleChange}
                        /> Staff status
                    </label>
                        <p><em style={{fontSize:"0.75rem"}}>Designates whether the user can log into this admin site.</em></p>

                    <label htmlFor="user">
                        <input className="user-form"
                        type="checkbox"
                        aria-label="Checkbox to set superuser privilege"
                        name="is_superuser"
                        checked = {data.is_superuser}
                        onChange={handleChange}
                        /> Superuser status
                    </label>
                    <p><em style={{fontSize:"0.75rem"}}>Designates that this user has all permissions without explicitly assigning them.</em></p>

                    <button className="float-md-start d-grid gap-2 d-md-block col-md-4 col-12 mx-auto btn btn-primary my-3 p-2" type="submit" style={{borderRadius: "5px", border:"none", color:"#fff", fontWeight:"bold", backgroundColor:"#014421"}}>
                        Update
                    </button>
                    {/* <Link reloadDocument to={'../users'} className="float-md-end d-grid gap-2 d-md-block col-md-4 col-12 mx-auto btn btn-primary my-3 p-2" onClick={deleteRecord} style={{borderRadius: "5px",border:"none", color:"#fff", fontWeight:"bold", backgroundColor:"#98001E"}}>
                            Delete
                    </Link> */}
                </form>
            </div>
        </div>
    </>
    );


};
export default Users;