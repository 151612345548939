import { useContext } from 'react';
import AuthContext from './context/AuthProvider.js';
import { Link } from "react-router-dom";

function Navbar() {
    const { user, logoutUser } = useContext(AuthContext);

    return (
        <nav className="navbar navbar-expand-lg sticky-top">
            <div className="container-fluid">
                <Link className="navbar-brand" to={user ? "/about" : "/"}>Flipbook</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" style={{ flexGrow: '0' }} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {user ? (
                            <>
                                <li className="nav-item"><Link className="nav-link" to="/home">Home</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/about">About Us</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/contact">Contact Us</Link></li>
                                {user.is_staff || user.is_superuser ? (
                                    <>
                                        <li className="nav-item"><Link className="nav-link" to="/colleges">Colleges</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/categories">Categories</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/flipbooks">Flipbooks</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/users">Users</Link></li>
                                    </>
                                ) : null}
                                <li className="nav-item">
                                    <div className="mt-1 dropdown">
                                        <div className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "none", color: "#fff" }}>
                                            <i className="bi bi-gear"></i>
                                        </div>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><button className="dropdown-item" onClick={logoutUser} style={{ backgroundColor: "#fff", color: "#850038" }}>Logout</button></li>
                                        </ul>
                                    </div>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="nav-item"><Link className="nav-link" to="/about">About Us</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/contact">Contact Us</Link></li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
