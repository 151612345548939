import { useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from './context/AuthProvider.js'
import GoogleLoginButton from "../components/GoogleLoginButton";
import UserContext from "../context/UserContext";
export default function UserLoginForm() {
    const { userInfo } = useContext(UserContext);
    console.log(userInfo);
    const {loginUser} = useContext(AuthContext)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const handleSubmit = e => {
        e.preventDefault();
    
        username.length > 0 && loginUser(username, password);
    
          console.log(username);
          console.log(password);
        setPassword("");
        setUsername("");
    };

    const userRef = useRef(null);
    return (
        <main className=''>
            <div className='container-fluid'>
                <div className='row mt-5 pt-3 align-items-center'>
                    <div className='col-md ms-5 d-none d-lg-block'>
                        <p className='brandlogo'>Flipbook</p>
                        <p className='brandlogo'>Thesis, News, and Magazine Publisher</p>
                    </div>
                    <div className='col-md d-md-flex'>
                        <form className='login-form p-4 me-5 mx-auto' style={{width:"60%"}} onSubmit={handleSubmit}>
                            <p className='text-center' style={{fontWeight:"bolder",fontSize:"1.2rem"}}>User Login</p>
                            {/* <p className='text-center' style={{fontSize:"0.8rem", color:"red"}} aria-live='assertive' ref={errRef}>{error}</p> */}
                            <div className="mb-3">
                                <label htmlFor="exampleInputUsername" className="form-label">Username</label>
                                <input className="form-control" id="exampleInputUsername"
                                    type="text" 
                                    ref={userRef}
                                    autoComplete='off'
                                    name='username'
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input className="form-control" id="exampleInputPassword1"
                                    type="password"
                                    name='password'
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button type="submit" className="btn btn-primary p-2 px-5" style={{backgroundColor:"#014421"}}>Login</button>
                            </div>


                            <div className="mt-2 d-flex justify-content-center">
                                <GoogleLoginButton />
                                </div>
                           
         
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}
