import { useContext} from 'react';
import AuthContext from './context/AuthProvider.js'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const HomepageLayout = (props) => {
    // const user = useUser();
    // const dispatch = useUserDispatch();
    const token = localStorage.getItem("authTokens");
    if (token){
        const decode = jwtDecode(token);
        var user_id = decode.user_id;
        var username = decode.username;
        var full_name = decode.full_name;
  
    }
    const {user, logoutUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const goHomePage = ()=> navigate("/about");
    return (
        <>
        {token !== null && 
        <div className='bg-up'>
            <div className="container py-5">
                <div className="row mx-md-5" style={{background: "#850038", boxShadow: "0px 4px 4px 5px rgba(0, 0, 0, 0.25)", borderRadius: "44px"}}>
                
                    <div className="col-md d-flex">
                        <span className="float-start mt-2 ms-2 p-2 z-3 position-absolute" style={{border:"none",backgroundColor:"#850038"}}><i className="bi bi-arrow-left" style={{color:"#fff", fontSize:"2rem", cursor: "pointer"}}  onClick={goHomePage} /></span>

                        <div className="d-flex flex-column m-auto p-3">
                        <p className="brand align-self-center" style={{lineHeight:"1.5", color:"#fff"}}>Flipbook</p>
                        <p className="brand align-self-center d-none d-sm-block" style={{fontSize:"1.2rem", color:"#fff"}}>Thesis and News Mag Publisher</p>
                        </div>
                                              
                    </div>
                    <div className="col-md p-3 upload-form"style={{borderLeft: "2px solid #5C0027"}}>
                        <span style={{color:"#fff"}}>Welcome {username}!</span>
                        <span className="float-end pe-3" style={{color:"#fff"}} onClick={logoutUser}>
                            <a href="/">Logout</a>
                        </span>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}
export default HomepageLayout;