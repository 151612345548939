import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );

    const [valid, setValid] = useState(false);
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwtDecode(JSON.parse(localStorage.getItem("authTokens")).access)
            : null
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const loginUser = async (username, password) => {
        const response = await fetch(BASE_URL + "/token/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                username, password
                })
            });
            const data = await response.json();

            if (response.status === 200) {
                setValid(true);
                setAuthTokens(data);
                setUser(jwtDecode(data.access));
                localStorage.setItem("authTokens", JSON.stringify(data));
            axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
                navigate("/about", { replace: true });
            alert("Logged In");
            } else {
                setError('Username or password incorrect');
            console.log("Error in logging in", response.status);
            alert("Unable to log in, try again");
        }
    };

    const googleLoginUser = async (access_token, username) => {
            const data = {
                access: access_token,
            refresh: "dummy-refresh-token" // Add a dummy refresh token since Google doesn't provide it
            };

            setValid(true);
            setAuthTokens(data);
        setUser({ username }); // Directly set username, decode JWT token if it's available
            localStorage.setItem("authTokens", JSON.stringify(data));
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            navigate("/about", { replace: true });
        alert("Logged In with Google");
    };

    const registerUser = async (email, username, password, password2, first_name, last_name) => {
        const response = await fetch(BASE_URL + "/register/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                email, username, password, password2, first_name, last_name
                })
            });
            if (response.status === 201) {
                navigate("/login");
            alert("Registration successful");
            } else {
            setError('Username or email exists already');
            console.log("Error in registering user", response.status);
            alert("Error occurred, try again");
        }
    };

    const logoutUser = () => {
        setValid(false);
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        navigate("/", { replace: true });
        alert("Logout successful");
    };



    const contextData = {
        valid,
        user,
        setUser,
        authTokens,
        setAuthTokens,
        registerUser,
        loginUser,
        googleLoginUser,
        logoutUser,
    };

    useEffect(() => {
        if (authTokens) {
            setUser(jwtDecode(authTokens.access));
        }
        setLoading(false);
        let interval = setInterval(() => {
            if (authTokens && valid) {
                setUser(jwtDecode(authTokens.access));
                
            }
            setLoading(false);
        }, 9000);
        return () => clearInterval(interval);
    }, [authTokens, loading]);

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
