import React, { useState, useEffect ,useRef } from "react";
import { BASE_URL, CLIENT_URL } from "../config";
import { useNavigate, Link } from 'react-router-dom';
import useAxiosWithInterceptor from "../helpers/jwtinterceptor.js";
import HomepageLayout from "./HomepageLayout.js";

function Homepage() {
    const navigate = useNavigate();
    
    // const user = useUser();
    
    const [data, setData] = useState({
        title:""
    });
    const [query, setQuery] = useState({
        title:""
    });
    const fileRef = useRef()
    const handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
        setQuery({
            ...query,
            [e.target.name]: value
        });
    };

    const [file, setFile] = useState(null);
    const [fileHash, setFileHash] = useState({
        text:">>Link Here<<",
        // abstract:"",
        // access:"",
        // author:"",
        pdf_file:"",
        title:""
    });
    // const [path, setPath]=useState("");

    const [uploaded,setUploaded] = useState(false);
    const handleFileUpload = (e) => {
        // console.log("files");
        // console.log(e.target.files);
        setFile(e.target.files[0]);
    }
    const clearForm = () => { 
        setData({title:""});
        setFile(null);
        fileRef.current.value = null
    };
    const [content, setContent] = useState([]);
    //  AXIOS POST
    const jwtAxiosPost= useAxiosWithInterceptor();
    const handleSubmit = async(e) => {
        e.preventDefault();
        const forData = new FormData();
        forData.append("pdf_file", file);
        forData.append("title", data.title);
        // forData.append("author",1);
        await jwtAxiosPost
        .post(BASE_URL + "/flipbooks/", forData, {headers: {
            'Content-Type': 'multipart/form-data'
        }})
        .then((response) => {
            console.log(response.status, response.data.token);
            if (response?.status === 201){
                setContent(response?.data);
                console.log("uploaded");
                setUploaded(true);
                checkUpload();
            }
        });
        clearForm();
    };
    
    // AXIOS GET
    const [isLoading, setLoading] = useState(true);
    function checkUpload (){
        return new Promise(()=>{
            console.log("content:",content);
            setFileHash({
                ...fileHash,
                pdf_file:content?.pdf_file,
                title:content?.title
            });
            setLoading(false);
            // setPath(getHashedFile(content.pdf_file));
        })
    };
    // const jwtAxiosGet= useAxiosWithInterceptor();
    useEffect(() => {
        const asyncFn = async () => {
            // await jwtAxiosGet
            // .get(BASE_URL + "/flipbooks/")
            // .then((response)=>{
            //     // setContent(response?.data);
            // })
            // .catch(error =>{
            //     console.log(error);
            // });
            await checkUpload();
        };
        asyncFn();
    }, [uploaded]);
    const getIsFormValid = () => {
        return ( 
            data.title && file 
        );
    };
    function getHashedFile(path){
        if (typeof path === 'string') {
            const arr = path.split("/");
            console.log("File:",arr[5]);
            return arr[5];
        }
    }
    const token = localStorage.getItem("authTokens");
    if (isLoading) {
        return(
            <>
            <HomepageLayout>
                <div>Loading...</div>
            </HomepageLayout>
        </>
        );
    }
    if(!uploaded && token){
        return (
        <HomepageLayout>
                <form className="d-flex flex-column px-md-5 p-3" onSubmit={handleSubmit}>
                    <div className="align-self-center pt-3"style={{fontSize:"1.5rem", lineHeight:"2.5", color:"#fff"}}>Upload your work.</div>
                    <div className="align-self-center pb-4"style={{color:"#fff"}}>Select only your PDF file...</div>
                    <div className="pb-3">
                        <input className="form-control" id="form_title" 
                            type="text"  
                            name="title"
                            placeholder="Title"
                            value={data.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pb-3">
                        <input className="form-control" id="form_file"
                            type="file"  
                            name="file"
                            accept='application/pdf'
                            onChange={handleFileUpload}
                            ref={fileRef}
                        />
                    </div>
                    <br/>
                    <button className="btn btn-primary p-3 px-md-5 mx-md-3 align-self-center" style={{background: "#014421", borderRadius: "10px", color:"#fff", border: "none"}}
                    id="submit" disabled={!getIsFormValid()}>
                        Upload Now
                    </button>
                </form>
            </HomepageLayout>
        );
        
    }
    if (uploaded && token){
        return(
            <HomepageLayout>
                <div className="d-flex flex-column align-items-center file-link">
                    <p className="m-5 mb-3">Congratulations! Your Document has been converted successfully!</p>
                    <p className="m-5 mt-3">Click the link below to view your flipbook document now!</p>
                    <Link target="_blank" to={`/view/${ getHashedFile(fileHash.pdf_file)}`} className="fs-3 fw-bold mb-3" onClick={() => {navigator.clipboard.writeText(`${CLIENT_URL}/view/${ getHashedFile(fileHash.pdf_file)}`)}} title="Click to copy and open link" style={{border:"none", color:"#fff",backgroundColor:"#850038", textDecoration:"none"}}>{fileHash.text}</Link>
                </div>
            </HomepageLayout>
        );
    }
    return(
        <div>Page Not Found</div>
    );
 
}
export default Homepage;