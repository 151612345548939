import React from 'react';
import useScrollListener from './context/useScrollListener';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export default function Navbar() {
    const [navClassList, setNavClassList] = useState([]);
    const scroll = useScrollListener();
  
    // update classList of nav on scroll
    useEffect(() => {
      const _classList = ["d-flex", "align-items-start", "ps-2"];
  
      if (scroll.y > 40 && scroll.y - scroll.lastY > 0)
        _classList.push("nav-bar--hidden");
  
      setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);

    const navigate = useNavigate();
    const goHomePage = ()=> navigate("/about");
    // const isView = false;
    return(
        <>
        <nav style={{zIndex:1000}} className={navClassList.join(" ")}>
          {/* <button onClick={handleClick(isView)}>go back</button> */}
          <button onClick={goHomePage} style={{border:"none",backgroundColor:"#850038"}}><i className="bi bi-arrow-left" style={{color:"#fff", fontSize:"2rem"}}/></button>
        </nav>
        </>
    );
  
}
