import { useState, useEffect } from "react";
// DateTimeField to Year, Month. Day
export function dateToString (props){
    const monthWord = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"] 
    if (props != null){
      let year = props.slice(0,4);
      let month = props.slice(5,7);
      let day = props.slice(8,10);
      return (`${year}, ${monthWord[month-1]}. ${day}`);
    }
    return; 
}
// https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */

export function useScroll() {
   // storing this to get the scroll direction
  const [lastScrollTop, setLastScrollTop] = useState(0);
   // the offset of the document.body
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
   // the vertical direction
  const [scrollY, setScrollY] = useState(bodyOffset.top);
   // the horizontal direction
  const [scrollX, setScrollX] = useState(bodyOffset.left);
   // scroll direction would be either up or down
  const [scrollDirection, setScrollDirection] = useState();

  const listener = e => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection
  };
}
function checkPostDate(a,b){
  let date1 = a.slice(0,10);
  let date2 = b.slice(0,10);

  let timeHour = a.slice(11,13);
  let timeMin = a.slice(14,16);
  let timezoneHour = a.slice(27,29);
  let timezoneMin = a.slice(30,32);
  // console.log(timeHour,":",timeMin,":",timezoneHour,":",timezoneMin);
  let time1 = `${timeHour-timezoneHour}:${timeMin-timezoneMin}`
  let time2 = b.slice(11,16)
  if (time1.length === 4){
      time1 = `0${time1}`
  }
  console.log(time1,"===",time2);
}