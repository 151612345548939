// axios interceptor to render different error pages per type
import axios, {AxiosInstance} from 'axios';
import {useNavigate} from 'react-router-dom';
import {BASE_URL} from '../config';
import { useContext} from 'react';
import AuthContext from '../components/context/AuthProvider';

const API_BASE_URL = BASE_URL;
// const token=localStorage.getItem('authToken.access')  ? JSON.parse(localStorage.getItem('authToken.access')) : "";
// const refresh=localStorage.getItem('authToken.refresh') ? JSON.parse(localStorage.getItem('authToken.refresh')) : "";


const useAxiosWithInterceptor = ()=>{
    const jwtAxios = axios.create({
        baseURL: API_BASE_URL,
        withCredentials: true,
        'Content-type':'application/json',
        // headers:{Authorization: localStorage.getItem('access') ? `Bearer ${token}` : null}
    
    })
    const navigate = useNavigate();
    const {setAuthTokens, setUser, setValid} = useContext(AuthContext);
    jwtAxios.interceptors.response.use(
        (response)=>{
            return response;
        },
        async(error) =>{
           
            if (error.response?.status === 401){
                const goDefault = ()=> {
                        navigate("*");
                }
                goDefault();
                console.log("Invalid Page/ Session Timed out");
                localStorage.removeItem("authTokens")
                setAuthTokens(null);
                setUser(null);
                setValid(false)
            }
            else if (error.response?.status === 400){
                // const goDefault = ()=> navigate("/register"); //Bad Request
                // goDefault();
                console.log("clear everything");
            }
            else if (error.response?.status === 403){
                const goDefault = ()=> navigate("/errorpage"); //FORBIDDEN
                goDefault();
            }
            else if (error.response?.status === 409){
                const goDefault = ()=> navigate("/home"); //CONFLICT
                goDefault();
            }
            throw error;
        }
        
    )
    

    // jwtAxios.interceptors.request.use(
    //     async req => {
    //         if (token){
    //             req.headers.Authorization = `Bearer ${token}`;
    //             const user = jwtDecode(token);
    //             const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    //             console.log("Expired:" , isExpired);
    
    //             if (!isExpired){
    //                 return req
    //             }
    //             else{
    //                 const res = await axios.post(`${BASE_URL}/auth/token/refresh/`,{refresh:refresh});
    //                 console.log("ResDataAccess",res.data.access);
    //                 if (res.status === 200){
    //                     localStorage.setItem('authToken.access', JSON.stringify(res.data.access));
    //                     req.headers.Authorization = `Bearer ${res.data.access}`;
    //                     return req;
    //                 }
    //                 else{
    //                     try{
    //                         await axios.post(`${BASE_URL}/logout/`,{"refresh_token":refresh})
    //                         .then((response) => {
    //                             console.log("Response",response);
    //                             if (response?.status === 200){
    //                                 localStorage.removeItem('authToken.access');
    //                                 localStorage.removeItem('authToken.refresh');
    //                             }
    //                         });
    //                     }
    //                     catch(error){
    //                         console.log("Error logging out", error);
                            
    //                     }
    //                 }
    //             }
                
    //         }
    //         return req;
    //     }
    // )
    return jwtAxios;
}
export default useAxiosWithInterceptor;