import React from 'react';
import Navbar from './Navbar2';
import { Link } from 'react-router-dom';

function About() {
  const styles = {
    main: {
      padding: '20px',
      backgroundColor: '#f9f9f9',
      minHeight: '100vh',
    },
    brandLogo: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#00573f',
      margin: '20px 0',
    },
    heading: {
      fontSize: '2rem',
      fontWeight: 'bold',
      margin: '20px 0',
      color: '#333',
    },
    subheading: {
      fontSize: '1.5rem',
      color: '#555',
      margin: '10px 0',
    },
    text: {
      fontSize: '1.2rem',
      color: '#666',
      margin: '10px 0',
    },
  };

  return (
    <>
      <Navbar />
      <main style={styles.main}>
        <div className="container-fluid">
          <div className="row mt-5 pt-3 align-items-center">
            <div className="col-12 text-center">
              <p style={styles.brandLogo}>Interactive Learning Center Diliman</p>
            </div>
            <div className="col-12">
              <h1 style={styles.heading}>Our Mission</h1>
              <ul>
                <li style={styles.text}>To promote the use of technologies for teaching and learning;</li>
                <li style={styles.text}>To support the interactive learning process through the development and production of educational materials;</li>
                <li style={styles.text}>To advance faculty expertise/skills in the development and production of interactive learning materials;</li>
                <li style={styles.text}>To implement policies and guidelines for the development and production of interactive learning materials;</li>
              </ul>
              <h1 style={styles.heading}>Our Vision</h1>
              <h6 style={styles.subheading}>ILC Diliman envisions being the leader in the development, deployment, and use of educational technology.</h6>
             
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default About;

