import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { useSortableData } from "../helpers/SortableData";
import { dateToString } from "../helpers/helper";
import Navbar from "./Navbar2";

function CategoryTable(props){
    const {items} = props;
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const itemsPerPage = 5;
    const [pageCount, setPageCount] = useState(0);
    const [endOffset, setEndOffset] = useState(0);
    const [page, setPage] = useState(0);

    const [loaded, setLoaded] = useState(false);

    const data = Object.values(items);
    const [query, setQuery] = useState("");
    const search_parameters = Object.keys(Object.assign({}, ...data));
    // const [filter, setFilter] = useState("");
    // const filter_items = [...new Set(data.map((item) => item.region))];
    
    function search(props) {
        return props.filter((item) =>
            // item.region.includes(filter) &&
            search_parameters.some((parameter) =>
                item[parameter].toString().toLowerCase().includes(query.toLocaleLowerCase())
            )
        );
    }

    useEffect(()=>{
        setEndOffset(itemOffset + itemsPerPage);
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(search(items).slice(itemOffset, endOffset));
        setPageCount(Math.ceil(search(items).length / itemsPerPage));
        setLoaded(true);
    },[itemOffset, endOffset, itemsPerPage, items ,query])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        setPage(event.selected);
    };

    if (!loaded) {
        return <>loading...</>;
    }
    else {
        return (
            <>
                <div className="search-wrapper float-end">
                        <label htmlFor="search-form">
                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="category-form"
                                placeholder="Search ..."
                                onChange={(e) => {setQuery(e.target.value); handlePageClick({selected :0});}
                                }
                            />
                            <span className=""></span>
                        </label>
                </div>
                <table id="category-table" className="table table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {search(currentItems)?.map((item, i) => {
                            return (
                                <tr id={item.id} key={i}>
                                    <td>{item.category}</td>
                                    <td>{dateToString(item.created)}</td>
                                    <td>{dateToString(item.updated)}</td>
                                </tr>
                            );
                        })}
                        {currentItems.length === 0 ? 
                            <tr>
                                <td>{"No content to display"}</td>
                            </tr>
                        : null}
                    </tbody>
                </table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination pagination-sm justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                />
            </>
        );
    }
}
export default CategoryTable;