import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Homepage from './components/Homepage';
import About from './components/About';
import Contact from './components/Contact';
import Colleges from './components/Colleges';
import RegisterForm from './components/RegisterForm';
import Categories from './components/Categories';
import Documents from './components/Documents';
import Users from './components/Users';
import Update from './components/Update';
import Edit from './components/Edit';
import Flip from './components/Flipbook';
import SessionTimeout from './helpers/SessionTimeout';
import {BrowserRouter, Routes, Route} from "react-router-dom"
// import { UserProvider } from './components/context/UserContext';
import { AuthProvider } from './components/context/AuthProvider';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
        <AuthProvider>
            <Routes>
                
                    <Route path="*" element={<App />}/>
                    <Route path="/home" element={<Homepage />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/contact" element={<Contact />} />
                    {/* <Route path="*" element={"Page not found"} /> */}
                    <Route path="*" element={<SessionTimeout/>} />
                    <Route path="/register26" element={<RegisterForm />} />
                    <Route path="/view/:hash" element={<Flip />} />
                    {/* Admin Routes */}
                    <Route path="/colleges" element={<Colleges />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/flipbooks" element={<Documents />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/update/:id" element={<Update />} />
                    <Route path="/edit/:id" element={<Edit />} />
            </Routes>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
