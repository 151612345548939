import {useState} from "react";
import {validateEmail} from "../helpers/helper.js"; 
import useAxiosWithInterceptor from '../helpers/jwtinterceptor.js';
import {useNavigate} from 'react-router-dom';
import { BASE_URL } from "../config.js";
import Navbar from "./Navbar2";
const PasswordErrorMessage = () => { 
    return ( 
        <p className='text-center' style={{fontSize:"0.7rem", color:"red"}}>Password should have at least 5 characters</p> 
    ); 
};



function RegisterForm() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [name,setName] = useState({
        first_name:"",
        last_name:""
    })
    const [password, setPassword] = useState({
        value: "", 
        isTouched: false, 
    }); 
    const [password2, setPassword2] = useState({
        value: "", 
        isTouched: false, 
    }); 
    const [errorMsg, setErrorMsg] = useState("");

    const getIsFormValid = () => {
        return ( 
            username && name.first_name && name.last_name &&
            validateEmail(email) &&
            password.value.length >= 5 &&
            password.value === password2.value
            // role !== "role" 
        );
    };
    
    const clearForm = () => { 
        setUsername("");
        setEmail("");
        setPassword({
            value: "",
            isTouched: false,
        });
        setPassword2({
            value: "",
            isTouched: false,
        }); 
        setName({
            first_name:"",
            last_name:""
        })
    }; 
    const navigate = useNavigate();
    const goDefault = ()=> navigate("/");
    const jwtAxios= useAxiosWithInterceptor();
    const handleSubmit = async (e) => { 
        e.preventDefault(); 
        console.log(email);
        console.log(password.value);
        setErrorMsg("");

        try {
            await jwtAxios
            .post(BASE_URL + "/register/", {
                username: username,
                email: email,
                password: password.value,
                password2: password2.value,
                first_name: name.first_name,
                last_name: name.last_name
            }
            // ,{
            //     Authorization: 'Bearer <access token>'
            // }
            )
            .then((response) => {
                console.log(response.data);
            });
            alert("Account created!"); 
            clearForm();
            goDefault();
        }
        catch (error) {
            if (!error?.response) {
              console.log("No Server Response");
            }
            else if (error.response?.status === 400){
              console.log("Email/ Username exists already");
              setErrorMsg("Email or Username exists already");
            }
            else{
                console.log("Registration Error occured");
            }
            clearForm();
        }
    }; 
    
    return ( 
        <>
        <main className='bg-up'>
            <Navbar/>
            <div className='container-fluid '>
                <div className='row pt-3 align-items-center'>
                    <div className='col-md ms-5 d-none d-lg-block'>
                        <p className='brandlogo'>Flipbook</p>
                        <p className='brandlogo'>Thesis and News Mag Publisher</p>
                    </div>
                    <div className='col-md d-md-flex justify-content-end'>
                        <form className='login-form p-4 me-5 mx-auto mb-3' style={{width:"60%"}} onSubmit={handleSubmit}>
                            <p className='text-center' style={{fontWeight:"bolder",fontSize:"1.2rem"}}>User Registration</p>
                            {/* Add error if user exists */}
                            <p className='text-center' style={{fontSize:"0.8rem", color:"red"}}>{errorMsg}</p>
                            <div className="mb-3">
                                <label htmlFor="exampleInputGivenName" className="form-label">First Name<sup>*</sup></label>
                                <input className="form-control" id="exampleInputGivenName"
                                    type="text" 
                                    value={name.first_name} 
                                    onChange={(e) => { 
                                        setName({ ...name, first_name: e.target.value }); 
                                    }} 
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputFamilyName" className="form-label">Last Name<sup>*</sup></label>
                                <input className="form-control" id="exampleInputFamilyName"
                                    type="text" 
                                    value={name.last_name} 
                                    onChange={(e) => { 
                                        setName({ ...name, last_name: e.target.value }); 
                                    }} 
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputUsername" className="form-label">Username<sup>*</sup></label>
                                <input className="form-control" id="exampleInputUsername"
                                    type="text" 
                                    value={username} 
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail" className="form-label">Email address<sup>*</sup></label>
                                <input className="form-control" id="exampleInputEmail"
                                    type="text" 
                                    value={email} 
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password<sup>*</sup></label>
                                <input className="form-control" id="exampleInputPassword1"
                                    type="password" 
                                    value={password.value} 
                                    onChange={(e) => { 
                                    setPassword({ ...password, value: e.target.value }); 
                                    }} 
                                    onBlur={() => { 
                                    setPassword({ ...password, isTouched: true }); 
                                    }} 
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword2" className="form-label">Repeat Password<sup>*</sup></label>
                                <input className="form-control" id="exampleInputPassword2"
                                    type="password" 
                                    value={password2.value} 
                                    onChange={(e) => { 
                                    setPassword2({ ...password2, value: e.target.value }); 
                                    }} 
                                    onBlur={() => { 
                                    setPassword2({ ...password2, isTouched: true }); 
                                    }} 
                                />
                            </div>
                            {password.isTouched && password.value.length < 5 ? ( 
                                <PasswordErrorMessage /> 
                            ) : null} 
                            <div className='d-flex justify-content-center'>
                                <button type="submit" className="btn btn-primary p-2 px-5" style={{backgroundColor:"#014421"}} disabled={!getIsFormValid()}>Create Account</button>
                            </div>
                            <span className="mt-2 d-flex justify-content-center" style={{fontSize:"small"}}>Already have an account?
                            <a href="/" style={{fontSize:"small"}}>Login</a>
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        </main>
        </> 
    ); 
} 

export default RegisterForm; 