import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { useSortableData } from "../helpers/SortableData";
import { dateToString } from "../helpers/helper";
import { Link } from "react-router-dom";

function UserTable(props){
    const {items} = props;
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const itemsPerPage = 5;
    const [pageCount, setPageCount] = useState(0);
    const [endOffset, setEndOffset] = useState(0);
    const [page, setPage] = useState(0);

    const [loaded, setLoaded] = useState(false);

    const data = Object.values(items);
    console.log("data of users",data);
    const [query, setQuery] = useState("");
    const search_parameters = Object.keys(Object.assign({}, ...data));
    const [filter, setFilter] = useState("");

    let active_users = items.filter(user => user.is_active)
    let staff_users = items.filter(user => user.is_staff)
    let superuser_users = items.filter(user => user.is_superuser)
    let inactive_users = items.filter(user => user.is_active === false)

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    let filteredData;
    switch(filter){
        case 'active':{
            filteredData = active_users;
            break;
        }
        case 'staff':{
            filteredData = staff_users;
            break;
        }
        case 'superuser':{
            filteredData = superuser_users;
            break;
        }
        case 'inactive':{
            filteredData = inactive_users;
            break;
        }
        default:{
            filteredData = items;
            
        }
    }
    console.log("filteredData",filteredData);
    function search() {
        return filteredData?.filter((item) =>   
            // filteredData &&
            search_parameters.some((parameter) =>
                item[parameter]?.toString().toLowerCase().includes(query.toLocaleLowerCase())
            )
        );
    }

    useEffect(()=>{
        setEndOffset(itemOffset + itemsPerPage);
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(search(items).slice(itemOffset, endOffset));
        setPageCount(Math.ceil(search(items).length / itemsPerPage));
        setLoaded(true);
    },[itemOffset, endOffset, itemsPerPage, items ,query, filter])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        setPage(event.selected);
    };

    if (!loaded) {
        return <>loading...</>;
    }
    else {
        return (
            <>
                <div className="search-wrapper float-end">
                        <label htmlFor="search-form">
                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="user-form"
                                placeholder="Search ..."
                                onChange={(e) => {setQuery(e.target.value); handlePageClick({selected :0});}
                                }
                            />
                            <span className=""></span>
                        </label>
                </div>
                <div className="select">
                <select value={filter} onChange={handleFilterChange}>
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="staff">Staff</option>
                    <option value="superuser">Superuser</option>
                    <option value="inactive">Inactive</option>
                </select>
                    <span className="focus"></span>
                </div>
                <table id="user-table" className="table table-hover">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Privilege</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {search(currentItems)?.map((item, i) => {
                            return (
                                <tr id={item.id} key={i}>
                                    <td>{item.username}</td>
                                    <td>{item.first_name} {item.last_name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.is_staff ?  "staff " : null} {item.is_superuser ? "superuser" : null} 
                                        {!item.is_staff && !item.is_superuser ? "none":null}
                                    </td>
                                    <td><Link className="btn btn-primary btn-sm text-decoration-none" to={`/update/${item.id}`} style={{borderRadius: "5px", border:"none", color:"#fff", backgroundColor:"#014421"}}>Update</Link></td>
                                </tr>
                            );
                        })}
                        {currentItems.length === 0 ? 
                            <tr>
                                <td>{"No content to display"}</td>
                            </tr>
                        : null}
                    </tbody>
                </table>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination pagination-sm justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                />
            </>
        );
    }
}
export default UserTable;