import React from 'react';
import Navbar from './Navbar2';

function Contact() {
  const styles = {
    main: {
      padding: '20px',
      backgroundColor: '#f9f9f9',
      minHeight: '100vh',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#00573f',  // Changed to #00573f color
    },
    subtitle: {
      fontSize: '1.5rem',
      color: '#555',
    },
    text: {
      fontSize: '1.2rem',
      color: '#666',
    },
    link: {
      color: '#007bff',
      textDecoration: 'none',
    },
    linkHover: {
      textDecoration: 'underline',
    },
  };

  return (
    <>
      <Navbar />
      <main style={styles.main}>
        <div className="container-fluid">
          <div className="row mt-5 pt-3">
            <div className="col-12 text-center">
              <h1 style={styles.title}>Contact Us</h1>
            </div>
            <div className="col-12 text-center mt-4">
              <h2 style={styles.subtitle}>Phone:</h2>
              <p style={styles.text}>(63-2) 8920-9556 / 8981-8500 loc 2058</p>
            </div>
            <div className="col-12 text-center mt-4">
              <h2 style={styles.subtitle}>Office Email:</h2>
              <p style={styles.text}>
                <a href="mailto:ilcdiliman@up.edu.ph" style={styles.link}>ilcdiliman@up.edu.ph</a>
              </p>
            </div>
            <div className="col-12 text-center mt-4">
              <h2 style={styles.subtitle}>Support Email:</h2>
              <p style={styles.text}>
                <a href="mailto:ilcdhelpdesk.upd@up.edu.ph" style={styles.link}>ilcdhelpdesk.upd@up.edu.ph</a>
              </p>
            </div>
            <div className="col-12 text-center mt-4">
              <h2 style={styles.subtitle}>Address:</h2>
              <p style={styles.text}>Rm. 201 DILC Bldg., corner Apacible St. and Magsaysay Ave., University of the Philippines, Diliman, Quezon City 1101</p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact;

