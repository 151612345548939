import React from 'react'

const SessionTimeout = () => {
    return (
        <>
            <div className='bg-up'>
            <div className="container py-5 my-5">
                <div className="row  m-auto" style={{width:"50%", background: "#fff", boxShadow: "0px 4px 4px 5px rgba(0, 0, 0, 0.25)", borderRadius: "44px", opacity:"0.75"}}>
                
                    <div className="d-flex flex-column align-center my-3">
                    <p className='align-self-center mt-3'>Session expired/ Invalid credentials</p>
                    <p className='align-self-center mb-3'>Login to continue</p>

                        <form className='d-flex flex-column' action='/'>
                            <button className="p-2 px-5 align-self-center" type="submit" style={{background: "#014421", borderRadius: "10px", color:"#fff", border: "none"}}>
                            Login
                            </button>
                        </form>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    )
}

export default SessionTimeout