import React, { useState, useEffect } from "react";
import { BASE_URL } from "../config.js";
import useAxiosWithInterceptor from "../helpers/jwtinterceptor.js";
import Navbar from "./Navbar2.js";
import { useParams } from "react-router-dom";
import { dateToString } from "../helpers/helper.js";

function Edit() {
    const {id} = useParams();
    const [data, setData] = useState({
        id:id,
        abstract:"",
        access:"",
        category:[],
        college:[],
        created:"",
        image_dir:"",
        pdf_file:"",
        status:0,
        title:"",
        updated:""
    });
    
    const [colleges,setColleges] = useState([]);
    const [categories,setCategories] = useState([]);
    const [name, setName] = useState('');
    const handleChange = (e) => {
        // const value = e.target.checked;
        setData({
            ...data,
            // [e.target.name]: value,
            [e.target.name]:  e.target.value,
            
        });
        // data.category.push({
        //     id: id,
        // })
    };

    // const jwtAxiosDelete = useAxiosWithInterceptor();
    // const deleteRecord = (e) => {
    //     jwtAxiosDelete.delete(BASE_URL + "/users/" + id)
    //     alert('Deleted user');
    // };

// AXIOS GET
    // const jwtAxiosGet= jwtAxiosInstance();
    const jwtAxiosGet = useAxiosWithInterceptor();
    const jwtAxiosGet2 = useAxiosWithInterceptor();
    
    useEffect(() => {
        jwtAxiosGet
        .get(BASE_URL + "/flipbooks/" + id + "/")
        .then((response)=>{
            console.log(response.data);
            setData({...data, abstract:response.data.abstract,
                              pdf_file:response.data.pdf_file,
                              access:response.data.access,
                            //   college:response.data.college,
                              image_dir:response.data.image_dir,
                              status:response.data.status,
                              title:response.data.title,
                              created:response.data.created,
                              updated:response.data.updated
                    });
        })
        .catch(error =>{
            console.log(error);
        })
    }, []);

    
    useEffect(() => {
        jwtAxiosGet2
        .get(BASE_URL + "/categories/")
        .then((response)=>{
            setCategories(response?.data);
            console.log("Categories",categories);
        })
        .catch(error =>{
            
            console.log(error);
        })
        
    }, []);
    

    //  AXIOS POST
    // const jwtAxiosPost= jwtAxiosInstance();
    const jwtAxiosPost = useAxiosWithInterceptor(); 
    const handleSubmit = (e) => {
        e.preventDefault();
        const bookData = {
            abstract:data.abstract,
            access:data.access,
            // category:,
            // college:data.college,
            image_dir:data.image_dir,
            status:data.status,
            title:data.title
        };
        jwtAxiosPost
        .patch(BASE_URL + "/flipbooks/" + id + "/", bookData)
        .then((response) => {
            console.log(response.status);
            // clearForm();
            alert("Flipbook updated!"); 
        });
    };
    return (
    <>
        <Navbar/>
        <div className="">
            <h1 className="m-2">Change Flipbook</h1>
            <div className="mx-5">
                <div className="m-md-4">
                    <p>
                        <span className="fw-semibold">Title: </span> 
                        <span>{data.title}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Pdf file: </span> 
                        <span>{data.pdf_file}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Date created: </span> 
                        <span>{dateToString(data.created)}</span>
                    </p>
                    <p>
                        <span className="fw-semibold">Last updated: </span> 
                        <span>{dateToString(data.updated)}</span>
                    </p>
                </div>
                
                <form onSubmit={handleSubmit} className="mx-4 pb-5">
                    <div className="form-floating pb-3">
                        <span className="fw-semibold">Abstract: </span> 
                        <textarea className="form-control" id="floatingTextarea1" style={{height: "120px", fontSize:"14px"}} name="abstract" onChange={handleChange} value={data.abstract}></textarea>
                        {/* <label for="floatingTextarea1">Abstract</label> */}
                    </div>
                    <div className="dropdown pb-5">
                        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Categories
                        </button>
                        <ul className="dropdown-menu">
                            {categories?.map((item,i)=>{
                                return (
                                    <li className="category-list ms-2">
                                        <input name="category" type="checkbox" onChange={handleChange} value={item.i}/>{item.category}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    
                    <button className="float-md-start d-grid gap-2 d-md-block col-md-4 col-12 mx-auto btn btn-primary my-3 p-2" type="submit" style={{borderRadius: "5px", border:"none", color:"#fff", fontWeight:"bold", backgroundColor:"#014421"}}>
                        Update
                    </button>
                    {/* <Link reloadDocument to={'../users'} className="float-md-end d-grid gap-2 d-md-block col-md-4 col-12 mx-auto btn btn-primary my-3 p-2" onClick={deleteRecord} style={{borderRadius: "5px",border:"none", color:"#fff", fontWeight:"bold", backgroundColor:"#98001E"}}>
                            Delete
                    </Link> */}
                </form>
            </div>
        </div>
    </>
    );


};
export default Edit;