import React, { useRef, useState, useEffect, useMemo } from 'react';
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Navbar from './Navbar';
import { useParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { MEDIA_URL } from '../config';

// Configure PDF.js worker
const pdfjsWorkerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerSrc;

const Page = React.memo(React.forwardRef(({ pageNumber, width, height }, ref) => {
    return (
        <div ref={ref} className='self-center mb-10'>
            <ReactPdfPage 
                pageNumber={pageNumber} 
                width={width} 
                height={height} 
                renderAnnotationLayer={false} 
                renderTextLayer={false}
                loading={
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }
            />
        </div>
    );
}));

function Flip() {
    const { hash } = useParams();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const size = useWindowSize();
    const book = useRef();
    
    // Memoize dimensions calculation
    const { width, height } = useMemo(() => {
        let w, h;
        if (size.width > 900 && numPages > 2) {
            w = Math.floor(size.width / 2 - 50);
            h = Math.floor(size.height + 250);
        } else {
            w = Math.floor(size.width);
            h = Math.floor(size.height);
        }
        return { width: w, height: h };
    }, [size.width, size.height, numPages]);

    // Memoize PDF URL
    const pdfUrl = useMemo(() => {
        const url = `${MEDIA_URL}/${hash}`;
        console.log("PDF URL:", url);  // Log the PDF URL for debugging
        return url;
    }, [hash]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log("PDF loaded successfully with", numPages, "pages.");
        setNumPages(numPages);
        setError(null);
        setLoading(false);
    };

    const onDocumentLoadError = (error) => {
        console.error('Error loading PDF:', error.message, error.name, error.stack);
        setError('Failed to load the PDF. Please try again later.');
        setLoading(false);
    };

    // Memoize page components to prevent unnecessary re-renders
    const pageComponents = useMemo(() => {
        if (!numPages) return null;
        
        return Array.from({ length: numPages }, (_, index) => (
            <Page 
                pageNumber={index + 1} 
                key={`${index}-${width}-${height}`}
                width={width} 
                height={height}
                scale={5.0}
            />
        ));
    }, [numPages, width, height]);

    useEffect(() => {
        // Reset loading state when PDF URL changes
        console.log("Resetting loading state, new PDF URL detected.");
        setLoading(true);
    }, [pdfUrl]);

    if (error) {
        return (
            <>
                <Navbar />
                <div className="alert alert-danger m-4" role="alert">
                    {error}
                </div>
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className='flipbook-center d-flex flex-column justify-content-center'>
                <div>
                    <Document 
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                        loading={
                            <div className="d-flex justify-content-center m-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                        options={{
                            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                            cMapPacked: true,
                        }}
                    >
                        {loading ? (
                            <div className="d-flex justify-content-center m-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <HTMLFlipBook 
                                key={`${width}-${height}`}
                                showCover={true}
                                maxShadowOpacity={0}
                                mobileScrollSupport={true}
                                drawShadow={false}
                                width={width}
                                height={height}
                                onFlip={(e) => setPageNumber(e.data)}
                                flippingTime={1000}
                                ref={book}
                            >
                                {pageComponents}
                            </HTMLFlipBook>
                        )}
                    </Document>
                </div>
            </div>
        </>
    );
}

export default React.memo(Flip);
